.pln-sltr-container {
  .pln-sltr-tle {
    font-size: 18px;
    font-weight: 500;
    color: #484848;
  }
  .btn-switch-ctr {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 7px;
  }
  .btn-switch {
    background-color: white;
    border: none;
    padding: 7px;
    border-radius: 7px;
    width: 90px;
    cursor: pointer;
    font-weight: 700;
  }
  .btn-switch-active {
    border: none;
    padding: 7px;
    border-radius: 7px;
    color: white;
    width: 90px;
    cursor: pointer;
  }
  .MuiFormControlLabel-label {
    font-size: 11.9px !important  ;
  }
}
