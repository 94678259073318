.subscriberTab {
  .MuiPaper-root {
    text-transform: capitalize !important;
    color: white;
  }
  .css-1ujykiq-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
    font-size: 12px;
    color: white;
  }

  .MuiTabs-flexContainer {
    background-color: #00249b;
  }

  .MuiTabs-indicator {
    background: white !important;
    display: none;
  }
  .MuiTab-root {
    color: white !important;
    padding: 4px !important;
    background-color: #00249b;
  }
  .Mui-selected {
    color: #00249b !important;
    z-index: 9;
    font-weight: bold;
    font-size: 12px;
    background-color: white;
  }
  .react-swipeable-view-container {
    will-change: auto !important;
  }


  @media only screen and (max-width: 425px) {
    .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller{
      overflow-x: scroll !important;
    }
  }
}
