.bg-green{
    background-color: #70f211 !important;
}

.bg-red{
    background-color: #f21111 !important;
}
.paddingbottom{
    padding-bottom:inherit !important;
}
.padBottom{
    padding-bottom: 1rem;
}
.colorWhite{
 
    text-decoration: none;
}
.downloadPdf{
    margin-right: 1rem !important;
}
.printDownload{
    padding-bottom: 1rem;
}

.borderColor{
    border: 1px solid #00249b !important;
    
}
.detailstatement{
    color: white;
    text-decoration: none;
}