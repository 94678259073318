.employeeList-container {
  padding-top: 70px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 30px;
  .employeelist-title {
    padding: 20px 0px 30px 0px;
    font-weight: 600;
    font-size: 22px;
  }
  .employeeList-table-padding {
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
  }
  .search-btns-padding {
    padding: 10px 0px 20px 0px;
  }
  .status-btn-table-active {
    padding: 5px;
    background-color: #70f211;
    color: white;
    border-radius: 6px;
    width: 70px;
    cursor: pointer;
  }
  .status-btn-table-inactive {
    padding: 5px;
    background-color: #f21111;
    color: white;
    border-radius: 6px;
    width: 70px;
    cursor: pointer;
  }
  .MuiTableCell-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ticketCard{
    height: 95px !important;
  }
}


@media only screen and (max-width: 425px) {
  .ticketList-container{
    // margin-top: 2rem !important;
  }
}
