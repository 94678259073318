.distributor-expiry-list-container {
  padding-top: 80px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 30px;
  .title-expiry {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
