.login-container {
  background-image: url("../../assets/images/loginBG.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .sub-image-cnt-login {
    background-image: url("../../assets/images/loginUserView.png");
    height: 430px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .login-form-cnt {
    height: 100%;
    align-items: center;
    /* width: 100%; */
    display: flex;
  }
  .logo-login {
    width: 200px;
    background-size: contain;
  }
  .title-login {
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .forgot-login {
    text-align: end;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #0374bc;
    font-weight: bold;
    cursor: pointer;
  }
  .login-btn {
    width: 100%;
    border: none;
    padding: 12px;
    background-color: #0374bc;
    color: white;
    border-radius: 7px;
    margin-top: 25px;
    cursor: pointer;
  }
}
