.changepswForm-container{
    .createPswBtn{
        padding: 1rem;
    }
    .MuiOutlinedInput-input {
        font-size: 14px;
      }
      .center {
        margin: auto;
        width: 50%;
        // border: 3px solid #73AD21;
      }
      .expenseForm {
        background: white;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        border-radius: 5px;
      }
      .labelText{
        top: -7px;
      }
}
