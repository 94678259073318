.prog-tile {
  height: 15px;
  cursor: pointer;
}
.text-center {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-transform: uppercase;
  padding-top: 10px;
}
