.dashboard-container {
  padding-top: 100px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 30px;
  .heading-title {
    padding: 25px 0px 25px 0px;
    color: #484848;
    font-weight: 600;
    font-size: 15px !important;
  }
  .space-btn-cnt-sts {
    padding: 25px 0px 25px 0px;
  }
  .heading-title-secondary {
    padding: 25px 0px 25px 0px;
  }
  .heading-title-bold {
    color: #484848;
    font-weight: bold;
    font-size: 15px !important;
  }
  .heading-title-blue {
    font-weight: bold;
    font-size: 15px;
  }

  @media only screen and (max-width: 425px) {
    .heading-title{
      margin-left: 0rem !important;
    }
  }
}
