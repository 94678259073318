.payment-summary-cnt {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  .payment-summary-header {
    padding: 10px 10px 11px 10px;
    background-color: #00249b;
    color: white;
    align-items: center !important;
  }
  .border-summary {
    border: 1px solid #a9a3a3;
  }
  .border-bottom-summary {
    border-bottom: 1px solid #f8f4f4;
    align-items: center;
  }
  .text-size {
    color: white;
    font-size: 13px;
    font-weight: 500;
  }
  .text-size-sub {
    font-size: 13px;
    font-weight: 500;
  }
  .right-align {
    text-align: right;
    padding: 0px 15px 0px 5px;
  }
  .downloadPayment {
    cursor: pointer;
  }
  .downloadFirst{
    padding-left: 6px;
  }
  .spacingContent{
    padding-left: 5px;
  }
}


