.admin-home-container {
 .adminBanner{
   width: 100%;
 }

 .rectangle {
  width: 22rem;
  height: 14rem;
  background-color: #5DCEFF;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-top: 2rem;
    margin-bottom: 2rem;
}
.computerImg{
  width: 50%;
  margin-bottom: 2rem;
  height: 18%;
}

.centerDiv {
  background-image: url("../../assets/images/adminbg.png");

 }
 
}