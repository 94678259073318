.addTicket-container {
  padding: 25px 40px 20px 40px;  
}

@media only screen and (max-width: 425px) {
  .addTicket-container {
    padding: 15px 15px 0px 14px; 
  }
}

