body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root-container {
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00249c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e81f7;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-space-between {
  justify-content: space-between;
}
.align-self-center {
  align-self: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-center {
  display: flex;
  align-items: center;
}
.justify-center {
  justify-content: center !important;
}

.flex-justify-center {
  display: flex !important;
  justify-content: center;
}

.text-align {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.BoxShadow {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
}
.justifyRight {
  justify-content: right;
}
.displayFlex {
  display: flex;
}

.colorBlue {
  color: #00249b !important;
}
.displayNone {
  display: none;
}
.fnt-size-14 {
  font-size: 14px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.floatEnd{
  float: right;
}
