.lang {
  padding-top: 100px;
}

.distributorHeading {
  padding-left: 2rem;
  color: #00249b;
  font-weight: bold;
  font-size: 15px;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
}

.breadcrumbPadding{
  padding-right: 3rem;
}
