.overview-chart-cntr {
  background-color: #f2f2f2;
  padding: 15px;
  .overview-chart-details {
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
  .overview-title {
    padding-top: 15px;
    color: #707070;
    font-weight: 700;
    font-size: 13px;
  }
  .overview-details-box {
    height: 15px;
    width: 15px;
    margin-right: 11px;
    margin-top: 3px;
  }
  .overview-chart-title {
    color: #707070;
    font-weight: 700;
    font-size: 13px;
  }
}
