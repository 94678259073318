.subscriberDetails-container {
  padding: 84px 30px 20px 40px;
}

.subscriberProfileCard {
  background: white;
  padding: 26px 9px 33px 30px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.subBoquet-list-cntr {
  padding: 60px 0px 10px 0px;
  .sub-boquetList-title {
    font-weight: bold;
    padding-bottom: 20px;
  }
}
.alacrt-list-cntr {
  .ala-cart-title {
    font-size: 13px;
    text-align: center;
  }
}
.ala-cart-header-title {
  font-size: 15px;
  font-weight: 600;
  padding: 30px 0px 30px 0px;
}

.sub-add-on-cnt {
  padding: 30px 60px 20px 60px;
}

.pay-now-status-btn{
  padding: 5px;
    background-color: #00249B;
    color: white;
    border-radius: 6px;
    width: 70px;
}