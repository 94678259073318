.expenses-container {
  padding: 84px 30px 20px 40px;
  .center {
    margin: auto;
    width: 35%;
    // border: 3px solid #73AD21;
  }

  .paddingInput {
    padding: 1rem;
  }

  .createHeader {
    background-color: #00249b;
    margin: 0px !important;
    padding: 13px 13px 13px 5px;
    width: 100%;
    color: white !important;
  }

  .expenseForm {
    background: white;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }

  .expense-title {
    padding-left: 1rem;
    font-size: 17px;
  }

  .expenseHeading {
    color: #131313;
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 2rem;
  }

  .floatRight {
    float: right;
    padding-right: 1rem;
  }

  @media only screen and (max-width: 600px) {
    .center {
      margin: 10px;
      width: 100%;
    }
  }

  .MuiTableCell-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .expense-form-padding {
    margin-top: 60px;
  }
}
