.main-layout-left {
  margin-left: 200px;
  transition: margin-left 0.2s ease;
}
.main-layout-left-none {
  margin-left: 0px;
  transition: margin-left 0.2s ease;
}

@media (max-width: 719px) {
  .main-layout-left {
    margin-left: 0px !important;
  }
}
