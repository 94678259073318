.ticketList-container {
  padding: 84px 30px 20px 40px;
  .ticket-list-table-cntr {
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
    margin-top: 25px;
  }
  .status-btn-table {
    padding: 5px;
    color: white;
    border-radius: 6px;
    width: 70px;
  }
  .search-btns-padding {
    padding: 10px 0px 20px 0px;
  }
  .MuiTableCell-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media only screen and (max-width: 1024px) {
    .paddingLeft {
      padding: 10px !important;
    }
  }

  .ticketdown {
    padding: 10px !important;
  }

  .cur-pointer {
    cursor: pointer;
  }

  .ticketCard {
    height: 95px !important;
  }
}
.padingnot {
  padding: 35px 30px 20px 40px !important;
}

.notification .subscriberTab .MuiPaper-root {
  color: inherit;
}

@media only screen and (max-width: 425px) {
  .padingnot {
    padding: 25px 3px 20px 20px !important;
}
}
