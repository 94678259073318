.enquiryList-container {
  padding: 84px 30px 20px 40px;
  .ticket-list-table-cntr {
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
    margin-top: 25px;
  }
  .status-btn-table {
    padding: 5px;
    color: white;
    border-radius: 6px;
    width: 82px;
  }
  .search-btns-padding {
    padding: 0px 0px 20px 0px;
  }
  .MuiTableCell-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
  .ticketCard{
    height: 95px !important;
  }
}
