.ticketList-container {
  padding: 84px 30px 20px 40px;
  .ticket-list-table-cntr {
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
    margin-top: 25px;
  }
  .status-btn-table {
    padding: 5px;
    color: white;
    border-radius: 6px;
    width: 70px;
  }
  .search-btns-padding {
    padding: 10px 0px 20px 0px;
  }
  .MuiTableCell-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 1024px) {
  .paddingLeft {
    padding: 10px !important;
  }
}

.ticketdown {
  padding: 10px !important;
}
.boxshadowticketview{
    border-radius: 12px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    margin-top: 25px;
    overflow: scroll;
    height: 13rem;
}

.ticket-view-details {
  border-left: 1px solid #d5dcec;
  padding-left: 0px;
  margin-left: 30px;
  position: relative;
  display: block;
}

.ticket-view-content {
  padding-left: 30px;
  padding-bottom: 20px;
  position: relative;
  display: block;
}

.ticket-view-content:after {
  position: absolute;
  content: "";
  display: inline-block;
  background: #fff;
  width: 15px;
  height: 15px;
  top: 3px;
  left: -7px;
  border-radius: 50%;
  border-width: 3px;
  border-color: #00249B;
  border-style: solid;
}

.ticket-view-content span {
  font-size: 18px;
  display: block;
  margin-top: 5px;
  color: grey;
}

.ticket-view-content p {
  color: #99abb4;
  font-size: 18px;
  margin-bottom: 0;
}

.header-ticket-title {
    background-color: #00249b;
    color: white;
    padding: 20px;
    border-radius: 15px;
}

  .ticket-view-table-cntr {
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    margin-top: 25px !important;
  }

  .textaligncenter{
    text-align: center !important;
  }

  .ticketViewCard .ticketCard{
    // width: 270px !important;
  }

  .ticketviewheader{
    font-size: 20px;
  }
  .padding15pxticket{
    padding: 15px;
  }

  .ticket-view-content-user {
    border-radius: 10px;
    border: 2px solid darkgray;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    margin-top: 15px !important;
    padding: 15px !important;
}

.padding5px{
  padding: 10px;
}

.userheaderdetails{
  font-size: 15px;
  color: #00249B;
  word-break: break-all;
}

.h-120px{
  height: 120px;
}

.messaging .mesgs {
  padding: 30px 15px 0 25px;
}

 .msg_history {
  height: 515px;
  overflow-y: auto;
}

 .type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}

//  .input_msg_write input {
//   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
//   border: medium none;
//   color: #4c4c4c;
//   font-size: 15px;
//   min-height: 48px;
//   width: 100%;
// }

 .msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}

.chatbox .css-1480iag-MuiInputBase-root-MuiInput-root:before {
   border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}
.chatbox .css-1480iag-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}
.chatbox .css-1480iag-MuiInputBase-root-MuiInput-root:before:hover {
  border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}

.chatbox .css-1480iag-MuiInputBase-root-MuiInput-root{
  padding: 10px !important;
}

.sendbtn{
  border: none;
  background-color: white;
  float:right;
}

.cardgrey{
  background-color: #f6f6f6;
}

.incoming_msg {
  position: relative;
}

.messaging .incoming_msg_img {
  display: inline-block;
  width: 6%;
}

.messaging .received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}

.messaging .received_withd_msg {
  width: 57%;
}