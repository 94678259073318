.sub-add-plan-container {
  padding-top: 50px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 30px;
  .planlist-title {
    padding: 20px 0px 30px 0px;
    font-weight: 600;
    font-size: 22px;
  }
 
}
.breadcrumbPlanContainer{
  padding-top: 100px;
  padding-right: 15px;
  padding-left: 30px;
}
