.receiptList-container {
    padding-top: 70px;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-left: 30px;
    .receipt-title {
      padding: 20px 0px 30px 0px;
      font-weight: 600;
      font-size: 22px;
    }
    .receiptList-table-padding {
      border-radius: 12px;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
      padding: 20px;
    }
    .search-btns-padding {
      padding: 10px 0px 20px 0px;
    }
    .status-btn-table-active {
      padding: 5px;
      background-color: #70f211;
      color: white;
      border-radius: 6px;
      width: 70px;
    }
    .status-btn-table {
      padding: 5px;
      color: white;
      border-radius: 6px;
      width: 82px;
    }
    .MuiTableCell-head {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .MuiTableCell-body {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
  }


