.tle-icn-ctr {
  background-color: #00249b;
  padding: 11px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  .tle-icn-title {
    color: white;
    font-size: 13px;
    font-weight: 500;
  }
  .tle-icn-count {
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin-left: 25px;
    align-items: center;
    display: flex;
  }
}
