.outList-container {
  padding-top: 70px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 30px;
  .collection-title {
    padding: 20px 0px 30px 0px;
    font-weight: 600;
    font-size: 22px;
  }
  .planSubList-table-padding {
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
  }
  .search-btns-padding {
    padding: 10px 0px 20px 0px;
  }
  .datePadding {
    padding-bottom: 1rem;
  }

  .datePaddingRight {
    padding-right: 1rem;
  }
  .status-btn-table {
    padding: 5px;
    color: white;
    border-radius: 6px;
    width: 82px;
  }
  .dateField {
    fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-top: none;
      /* border-bottom: 0px !important; */
      border-right: none;
      border-left: none;
      border-radius: unset;
    }
  }
  .MuiTableCell-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
