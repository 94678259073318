.ticketDetails {
  .MuiOutlinedInput-root {
    padding-left: 0px !important;
  }
}

.addonService {
  .rupee-style{
    p {
      background: #00249b;
      padding: 8px;
      border-radius: 4px;
      color: white;
      font-size: 14px;
    }
  }
  
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}
