.cust-plan-cnt {
  .titleView-custPlan {
    padding: 10px;
    background-color: #00249b;
    color: white;
    text-align: center;
    border-radius: 7px;
    font-size: 14px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }
  .titleView-custPlan-white {
    padding: 10px;
    background-color: white;
    color: #00249b;
    text-align: center;
    border-radius: 7px;
    font-size: 14px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }
}


@media only screen and (max-width: 425px) {
  .paypop{
    width : 15rem !important;
  }
}
