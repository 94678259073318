.dashboard-container {
  padding-top: 100px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 30px;
  .heading-title {
    padding: 25px 0px 25px 0px;
    color: #484848;
    font-weight: bold;
    font-size: 17px;
  }
  .space-btn-cnt-sts {
    padding: 25px 0px 25px 0px;
  }
  .heading-title-secondary {
    padding: 25px 0px 25px 0px;
  }
  .heading-title-bold {
    color: #484848;
    font-weight: bold;
    font-size: 17px;
  }
  .heading-title-blue {
    font-weight: bold;
    font-size: 15px;
  }


}

// @media only screen and (max-width: 425px) {
//   .dashboard-container  {
//     padding-top: 160px;
//   }

// }

// @media only screen and (max-width: 375px) {
//   .dashboard-container  {
//     padding-top: 207px;
//   }

// }
