.planheading-title {
  // padding: 25px 0px 25px 0px;
  padding-top: 1rem;
  padding-left: 2rem;
  color: #131313;
  font-weight: bold;
  font-size: 17px;
}

.planHeading {
  color: #131313;
  font-weight: bold;
  font-size: 17px;
  padding-bottom: 1rem;
}

.addPlan-container {
  padding: 125px 60px 25px 60px;
  .planHeading {
    color: #131313;
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 3rem;
  }
}
