.table-viewable {
  .MuiTableCell-root {
    border-bottom: 0px !important;
    padding: 11px !important;
    font-size: 13px !important;
  }
  .MuiCheckbox-root {
    padding: 0px !important;
  }
}

.table-billing{
  height: auto;
  max-height: 19rem;
  .MuiTableCell-root {
    border-bottom: 0px !important;
    padding: 11px !important;
    font-size: 13px !important;
  }
  .MuiCheckbox-root {
    padding: 0px !important;
  }
}



.addplan-res{
  max-height: 14rem;
}

