.txtwidth {
  width: 100%;
}

.btnright {
  justify-content: right;
}

#basicadd {
  height: 100px;
}

.martop {
  margin-top: 1px !important;
}
.container {
  padding-top: 100px;
}

#boxsmall {
  height: 16px;
}
#boxbig {
  height: 100px;
}

.field-error {
  color: red;
  font-size: 13px;
  padding-left: 3px;
}

.distributorcard {
  background: white;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  .MuiOutlinedInput-input {
    font-size: 14px;
  }

  .date-picker-height {
    .MuiOutlinedInput-root {
      height: 36px;
    }
  }
}
