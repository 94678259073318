.search-input {
  border-radius: 6px !important;
  outline: none;
  border: 1px solid #00249b !important;
  width: 100%;
  .MuiInputBase-input {
    padding: 3px;
  }
}

.searchWith-btn {
  .MuiFormLabel-root-MuiInputLabel-root {
    top: -10px !important;
  }
  a {
    text-decoration: none;
    color: #00249b;
  }
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    height: 2rem !important;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px 4px 7.5px 6px !important;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px 4px 7.5px 6px !important;
  }
}

@media only screen and (max-width: 600px) {
  .serachBarButtons{
    margin-top: 1rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .emptyContainer{
    display: none;
  }
}