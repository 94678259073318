#demo-customized-button {
  color: black;
  background-color: white;
}

.topcolor {
  background-color: white !important;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06) !important;
  position: fixed !important;
  z-index: 400;
}

.account-circle {
  background-color: grey !important;
}

.topImage{
  cursor: context-menu !important;
}

@media only screen and (max-width: 600px) {
  .topImage  {
    // margin-left: 3rem !important;
    display: none !important;
  }

}

@media only screen and (max-width: 320px) {
  .pln-sltr-container  {
    margin-top: 2rem;
  }

}