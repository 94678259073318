.makePayment-cntr {
  .padding-btw-tiles {
    padding: 13px 0px 13px 0px;
  }
  .make-payment-form-title {
    font-weight: 600;
    padding-bottom: 10px;
    font-size: 13px;
  }
  .payment-header-title {
    font-size: 23px;
    font-weight: 600;
  }
  .payment-title-padding {
    padding-top: 10px;
  }
  .divider-line {
    border: 1px solid #d9d9d9;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
  .payment-table-title {
    color: white !important;
  }
  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight.MuiTableCell-sizeMedium.css-1azl6jz-MuiTableCell-root {
    color: white !important;
    text-align: inherit;
  }
  .btns--pay-stack {
    padding: 5px 0px 4px 24px;
  }
  .rectanglemakepay {
    width: 200px;
    height: 85px;
    background: #00249b !important;
    color: white;
    margin-right: 1rem;
    border-radius: 5px;

    p {
      padding: 1rem;
    }
  }

  // @media only screen and (max-width: 425px) {
  //   .rectanglemakepayReport {
  //     p {
  //       padding: 11px !important;
  //       padding-left: 0px !important;
  //     }
  // }
  // }

  .rectanglemakepayReport {
    width: 210px;
    height: 108px;
    background: #00249b !important;
    color: white;
    margin-right: 1rem;
    border-radius: 5px;

    p {
      padding-left: 11px;
    }
  }

  .recpayGreen {
    background-color: #47bc47;
    width: 210px;
    height: 108px;
    color: white;
    margin-right: 1rem;
    border-radius: 5px;

    p {
      padding-left: 11px;
    }
  }

  .payRed {
    background-color: #d35352;
    width: 210px;
    height: 108px;
    color: white;
    margin-right: 1rem;
    border-radius: 5px;

    p {
      padding-left: 11px;
    }
  }

  .payPink {
    background-color: #d352a5;
    width: 210px;
    height: 108px;
    color: white;
    margin-right: 1rem;
    border-radius: 5px;

    p {
      padding-left: 11px;
    }
  }

  .payPurple {
    background-color: #7152d3;
    width: 210px;
    height: 108px;
    color: white;
    margin-right: 1rem;
    border-radius: 5px;

    p {
      padding-left: 11px;
    }
  }

  .marginBottomStb {
    margin-bottom: 1.5rem !important;
  }
}
.payment-scroll {
  height: 95%;
  overflow: scroll;
}

.planTable {
  border: 1px solid lightgray;
}

.marginLeftAddplan {
  margin-left: 2rem;
}

.text-plan {
  padding-bottom: 15px;
  font-size: 16px;
}

.typeValu {
  margin-top: 5px !important;
}

.channel-list .table-viewable tr:first-child th {
  font-weight: bold;
}

.custr-profile-popup-cnt {
  height: 100%;
  overflow: scroll;
  background-color: white;
  .header-profile-title {
    padding: 10px;
    background-color: #00249b;
    color: white;
  }
  .profile-image-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}
.addplan-cntr {
  height: 70% !important;
  overflow: scroll;
}
.channel-cntr {
  height: 60% !important;
}

.history-cntr .payment-header-title {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  padding: 3px;
}

.history-cntr {
  height: 75% !important;
  overflow: scroll;
  .DownloadBtn {
    float: right !important;
  }
}

.borderboxpayment {
  padding: 10px;
  border-color: #00249b;
  border-style: solid;
  border-width: 3px;
}
.margintop20px {
  margin-top: 17px !important;
}

.ticketRaise {
  .header-raise-title {
    background-color: #00249b;
    color: white;
    padding: 10px;
  }
  .padding1rem {
    padding: 1rem;
  }
  .margin {
    margin-top: 2rem;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}

.addservicecurrent .sub-addontv-form {
  padding: 0px 0px 0px 0px !important;
}

.broadcasttable .table-viewable {
  height: 220px !important;
}

.brodTab .table-viewable {
  height: 150px !important;
}

.paddingtopbouq {
  padding-bottom: 13px;
}

.listview .table-viewable {
  height: 280px !important;
}

.lasttransction .table-viewable {
  height: 255px !important;
}

.AssignSeachContainer {
  position: relative;
  .AssignSearchResultsAssign {
    background-color: white;
    left: 42px !important;
    cursor: pointer;
    padding: 1.3rem;
    position: absolute;
    border-bottom: 1px solid lightGrey;
    max-height: 45%;
    overflow-y: scroll;
    z-index: 1;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  }
  .searchresult {
    border-bottom: 1px solid lightgrey;
    padding: 10px;
  }
}

.invent-add {
  overflow: scroll;
  height: 90%;
}

.stb-head {
  th {
    color: white !important;
  }

  background-color: #00249b;
}

.field-error {
  color: red;
  font-size: 13px;
  padding-left: 3px;
}

.tableView {
  margin-top: 1rem;
}

.currentPlan {
  height: 50%;
  overflow: scroll;
}

// .raiseHeight {
//   height: 65% !important;
// }

.date-picker-height {
  .MuiOutlinedInput-root {
    height: 36px;
  }
}

.ticketraiseImg {
  min-height: 7rem !important;
  height: 1rem;
}

.paddingspace {
  padding-left: 10px;
}

.pdfDownload {
  padding: 10px;
  width: 100%;
}

// .addplan-container {
//   height: 80%;
//   overflow: scroll;
// }

.pdfTable {
  .tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root {
    background-color: white;
  }
}

@media only screen and (max-width: 600px) {

  .pdfRes{
    width: 88% !important;
  }

  .paddingspace{
    padding-left: 0px !important;
  }

  .floatEnd{
    float: none !important;
  }

  .addPlanPopRes{
    width: 95% !important;
    // height: 90% !important;
  }

  .alacarRes{
    height: 76px !important;
  }

  .assignPopRes{
    width: 16rem !important;
  }



}



