.addEnquiry-form-cnt {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 15px 20px 5px 43px;
  border-radius: 12px;
  .add-enquiry-title {
    font-size: 20px;
    font-weight: bold;
  }
  .padding-title-addenquiry {
    padding: 10px 0px 10px 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .btn-enquiry-form {
    display: flex;
    justify-content: flex-end;
  }
  .textarea-add-enquiry {
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    outline: none;
    border-color: silver;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}
