.settings-tab-cnt {
  padding: 100px 30px 20px 40px;
  .heading-title {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .react-swipeable-view-container {
    will-change: auto !important;
  }
}
