.bulk-form-cnt {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 15px 20px 5px 43px;
  border-radius: 12px;
  .add-ticket-title {
    font-size: 20px;
    font-weight: bold;
  }
  .padding-title-bulkform {
    padding: 10px 0px 10px 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .btn-bulk-form {
    display: flex;
    justify-content: flex-end;
  }
  .textarea-add-bulk {
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    outline: none;
    border-color: silver;
  }
  .date-picker-height {
    .MuiOutlinedInput-root {
      height: 36px;
    }
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
  
}
.BulkSeachContainer {
  position: relative;
  .AssignSearchResultsAssign {
    background-color: white;
    left: 42px !important;
    cursor: pointer;
    padding: 1.3rem;
    position: absolute;
    border-bottom: 1px solid lightGrey;
    max-height: 45%;
    overflow-y: scroll;
    z-index: 1;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  }
  .searchresult{
    border-bottom: 1px solid lightgrey;
    padding: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .btn-bulk-form  {
   margin-top: 1rem;
  }

  .bulksmsTable{
    width: 17rem !important;
  }

}

@media only screen and (max-width: 900px) {
  .bulksmsTable{
    width: 34rem;
  }

}

@media only screen and (max-width: 400px) {
  .bulksmsTable{
    width: 15rem !important;
  }

}