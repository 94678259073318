.subscriberList-container {
  padding: 84px 30px 20px 40px;

  .subcardEdit{
    margin-top: 15px;
  }

  .title-text-box{
    font-size: 13px;
  }
  
  .subEditMain {
    color: #131313;
    font-weight: bold;
    font-size: 17px;  
    padding-bottom: 1rem;
  }
  
  .subeditmainborder{
    border-radius: 12px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    margin: 25px;
    padding: 35px;
  }
  
  .subEditCard .MuiOutlinedInput-input {
    font-size: 14px !important;
  }

  .subcardEditActiveplan {
    margin-top: 15px;
    .MuiListItemText-primary{
      font-size: 13px;
    }
  }
  

}

