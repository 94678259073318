.distributionList-container {
  padding-top: 100px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 30px;
  .status-btn-table-active {
    padding: 5px;
    background-color: #009617;
    color: white;
    border-radius: 6px;
    width: 70px;
    cursor: pointer;
  }
  .status-btn-table-inactive {
    padding: 5px;
    background-color: #c40000;
    color: white;
    border-radius: 6px;
    width: 70px;
    cursor: pointer;
  }
  .search-btns-padding {
    padding: 10px 0px 20px 0px;
  }
  .MuiTableCell-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cur-pointer {
    cursor: pointer;
  }
}
