.labelText {
  font-weight: bold;
  padding-right: 1rem;
  color: grey;
}

.profileIcon {
  border-radius: 50%;
  width: 10%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.centerProfile {
  margin: auto;
  width: 65%;
}

.marginAuto{
  margin: auto !important;
}

.labelWidth{
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  left: 4rem;
}

.labelWidth{
.css-1p823my-MuiListItem-root{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
}

.profile-container{
  .paddingSave{
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}

.profile-list-table-cntr {
  border-radius: 12px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin-top: 25px;
}

.textCenterProfile {
  margin: auto;
  width: 105%;
}

.profileDelete{
  margin-top: 1rem !important;
}



@media only screen and (max-width: 425px) {
  .profileRes{
    margin-left: -4rem !important;
  }

  .centerProfile{
    width: 90%;
  }

  .ProfileImg{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

}
@media only screen and (max-width: 400px) {

  .centerProfile{
    width: 96%;
  }


}