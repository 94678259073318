.add-sub-plan-cnt {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 20px;
  .title-card {
    font-size: 18px;
    font-weight: bold;
  }
  .form-bdy-padding {
    padding: 20px 0px 20px 0px;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}
