.sub-edit-card {
  border: 2px solid;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 17px 0px 17px 0px;

  .mainTitle {
    color: #131313;
    font-weight: bold;
    font-size: 17px;
    padding-left: 2rem;
  }

  .cur-pointer {
    cursor: pointer;
  }
}

