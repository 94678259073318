.selectable-subcriber {
  height: 12px;
  background-color: #00249b;
}

.breadcrumb-padding{
  padding-bottom: 1rem;
}

.add-subscriber-form-cnt {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 15px 40px 20px 40px;
  border-radius: 12px;
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
  .subscriberTab {
    padding-top: 2rem;
  }

  .subscriberTabRight {
    padding-top: 2rem;
  }

  .selectable-subcriber-right {
    height: 12px;
    background-color: lightgray;
  }

  .tabText {
    font-size: 15px;
    color: lightgray;
  }
  .add-subscriber-form-title {
    font-weight: 500;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}
