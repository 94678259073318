.generateBill-container {
    padding-top: 70px;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-left: 30px;
    .genrateBill-title {
      padding: 20px 0px 30px 0px;
      font-weight: 600;
      font-size: 22px;
    }
    
    .search-btns-padding {
      padding: 10px 0px 20px 0px;
    }
    .status-btn-table-active {
      padding: 5px;
      background-color: #70f211;
      color: white;
      border-radius: 6px;
      width: 70px;
    }
    
  }


