.carousel-wrapper
.carousel,
.carousel > .slider-wrapper,
.carousel > .slider-wrapper > .slider {
  height: 100%;
}

.carousel .slide img {
  height: 100vh !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.carousel-wrapper{
  .slick-next {
    display: none !important;
  }
}


.home-container {
  .div-relative {
    position: relative;
    .home-login {
      width: 200px;
      height: 54px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .login-btn-home {
      color: white;
      padding: 5px 10px 5px 10px;
      border-radius: 7px;
      background-color: #739af0;
      cursor: pointer;
    }
  }
  .features-title {
    text-align: center;
    padding: 50px 0px 50px 0px;
    font-size: 1.8rem;
    font-weight: 500;
    color: #005579;
  }
  .circle-features {
    height: 130px;
    width: 130px;
    background-color: #f4a41d;
    border-radius: 100px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .features-sub-title {
    color: #f4a41d;
    text-align: center;
    padding: 5px 0px 10px 0px;
  }
  .features-sub-description {
    color: #005579;
    text-align: center;
  }
  .dotted-border-features {
    // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F4A41DFF' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
    padding: 30px 0px 30px 0px;
  }
  .slick-prev {
    left: -45px;
  }
  .slick-next {
    right: -45px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 40px !important;
    line-height: 0.7 !important;
    color: gray !important;
  }
  .slider-images {
    height: 200px;
    width: 319px;
    background-size: contain;
  }

}

.div-absolute {
  position: absolute;
  top: 50px;
  left: 0;
  margin-left: 50px;
  margin-right: 50px;
  justify-content: space-between;
  width: 91%;
}

.carHeight{
  width: 100%;
  height: 100%;
}


@media only screen and (max-width: 425px) {
  .homeTop{
    margin-left: -40px !important;
  }
  .login-btn-home{
    margin-right: 1rem;
  }
  .slider-images {
    height: 200px !important;
    width: 224px !important;
    background-size: contain;
  }
  .carHeight{
    width: 300vh;
    height: 100vh;
  }
  .carousel .slide img {
    height: 50vh !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
}
