.settingspayment-container {
  padding-top: 45px;
  .settingspaymentbtn {
    padding: 1rem;
    justify-content: center;
  }

  .settingspayment-title {
    padding-left: 1rem;
    font-size: 17px;
  }
  .createHeader {
    background-color: #00249b;
    margin: 0px !important;
    padding: 13px 13px 13px 5px;
    width: 100%;
    color: white !important;
  }
  .headerpayment {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}
.settingstax-container {
  padding-top: 45px;
  .header-tax {
    background-color: #00249b;
    margin: 0px !important;
    padding: 13px 13px 13px 5px;
    width: 100%;
    color: white !important;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .settingstaxbtn {
    padding: 1rem;
    justify-content: center;
  }

  .settingstax-title {
    padding-left: 1rem;
    font-size: 17px;
  }
}
.border-sts-pay {
  border: 1px solid #c1b4b4;
}
.email-temp-cnt {
  padding-top: 45px;
  .email-temp-header {
    background-color: #00249b;
    padding: 13px 13px 13px 20px;
    color: white;
    border-radius: 13px 13px 0px 0px;
  }
  .email-temp-bdy {
    background-color: #f2f2f2;
    padding: 15px 15px 15px 15px;
  }
  .outline {
    border: 1px solid #a4a4a4;
    border-radius: 5px;
    padding: 10px 5px 10px 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .outline-center {
    border: 1px solid #a4a4a4;
    border-radius: 5px;
    padding: 10px 5px 10px 5px;
    font-size: 14px;
    text-align: center;
  }
  .bold-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }
}

.bulkimgupload {
  border: 2px solid #d9d9d9;
  padding: 0px 0px 0px 13px;
}

.exportstatement{
  color: white;
  text-decoration: none;
}


.bulkimgcolor {
  color: black !important;
  background-color: lightgray !important;
}

.bulktextcolor {
  color: black !important;
  font-size: 20px !important;
}

.bulkimggreen {
  color: white !important;
  background-color: rgb(48, 236, 48) !important;
}

.downloadbulk {
  width: 100px;
  height: 45px;
  margin-left: 140px !important;
}

.paddingTable{
padding-top: 3rem;
}

.timer-btn {
  padding: 1rem;
}

.hours {
  border: 1px solid !important;
  text-align: center;
}

.marginTopTime {
  top: 2rem;
  position: relative;
}

.AgentForm {
  background: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border: 1px solid black;
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    padding: 5px;
    background: lightgray;
  }
}

.agentWork-container {
  padding-top: 45px;
  .workTime-Header {
    background-color: #00249b;
    padding: 13px 13px 13px 20px;
    color: white;
    border-radius: 13px 13px 0px 0px;
  }
}

.set-order-cnt {
  padding: 60px 0px 10px 0px;
  .other-screen-title {
    font-size: 14px;
    font-weight: 600;
    padding: 0px 0px 30px 0px;
  }
  .other-screen-form {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-top: none !important;
  }
  .set-other-header {
    padding: 15px;
    background-color: #00249b;
    color: white;
    font-size: 17px;
    font-weight: 400;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .bdy-padding {
    padding: 20px 30px 30px 30px;
  }
  .bdy-title {
    padding: 20px 0px 10px 0px;
    font-size: 14px;
  }
  .btn-padding {
    padding-top: 30px;
  }
}

.paddingInput {
  padding: 1rem !important;
}

.MuiTableCell-head {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.MuiTableCell-body {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.settingsBluk-container {
  padding-top: 45px;
  .createHeader {
    background-color: #00249b;
    margin: 0px !important;
    padding: 13px 13px 13px 5px;
    width: 100%;
    color: white !important;
  }

  .headerpayment {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .settingsbulk-title {
    padding-left: 1rem;
    font-size: 17px;
  }
  .bulkuploadimport {
    font-size: 22px;
  }
  .uploadBtn {
    padding: 10px;
  }
  .bulkheight {
    overflow: scroll;
  }
 
  .bulk-form-title {
    font-weight: 600;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 13px;
  }
  
}

.popupConfirm{
  padding-top: 2rem;
}

.tymbtn{
  position: relative;
  bottom:10px;
  right:20px;
}
.padBottom{
  margin-bottom: 1rem !important;
}

.cursor-auto{
  cursor: auto !important;
}

@media only screen and (max-width: 768px) {
  .uploadBtn{
    display: initial !important;
  }

  .bulkUpload-btn {
    width: 8.5rem !important;
    margin-left: 11px !important;
  }
}