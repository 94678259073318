.title-container {
  padding-top: 1rem;
  color: #131313;
  font-weight: bold;
  font-size: 17px;
}

.ticket-generate {
  padding: 20px 0px 20px 0px;
}
.sub-bouqut-form-cnt{
  padding: 40px 40px 40px 40px;
}
.ticketDescription {
  display: grid;
}

.btn-ticket {
  padding-right: 7rem !important;
}

.MuiAutocomplete-option {
  color: black;
}

.paddingticketgen{
  padding: 40px 40px 40px 90px !important;
}

.padding-btw-tiles {
  padding: 13px 0px 13px 0px;
}

