.count-status-tile-cnt {
  background-color: #f2f2f2;
  border-radius: 7px;
  .cnt-tle-cnt {
    background-color: #00249b;
    padding: 40px 10px 40px 20px;
    border-radius: 7px;
    align-items: center;
  }
  .cnt-title {
    color: white;
    font-size: 14px;
  }
  .status-title {
    color: #707070;
    font-size: 14px;
  }
  .status-divder {
    border: 1px solid #d9d9d9;
    width: 100%;
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 425px) {
  .emp-icon {
    display: none;
  }

  .Status-icon{
    text-align: center;
  }

  // .mainIcon{
  //   margin-left: -4px !important;
  // }
}
