.privacyContainer{
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
 .textCenter{
  text-align: center;
  color: #005579;
 }
 .privacyColor{
  color: #005579;
 }
}