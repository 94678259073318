.HardwareList-container {
  padding: 84px 30px 20px 40px;
  .ticket-list-table-cntr {
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
    margin-top: 25px;
  }
  .status-btn-table {
    padding: 5px;
    color: white;
    border-radius: 6px;
    background-color: #00249B;
    cursor: pointer;
  }
  .search-btns-padding {
    padding: 0px 0px 20px 0px;
  }
  .MuiTableCell-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
}

@media only screen and (max-width: 425px) {
  .hardwareTable {
  width: 95% !important;
  }

}