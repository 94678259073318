.reset-pass-sub-cnt {
  padding: 60px 0px 10px 0px;
  .reset-pass-title {
    font-size: 14px;
    font-weight: 600;
    padding: 0px 0px 30px 0px;
  }
  .reset-sub-form {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }
  .reset-sub-header {
    padding: 15px;
    background-color: #00249b;
    color: white;
    font-size: 17px;
    font-weight: 400;
  }
  .bdy-padding {
    padding: 20px 30px 30px 30px;
  }
  .bdy-title {
    padding: 20px 0px 10px 0px;
    font-size: 14px;
  }
  .btn-padding {
    padding-top: 30px;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}
