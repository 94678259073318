.ticketCard {
  height: 124px;
  // width: 100px;
  margin: 1rem !important;
  display: grid;
  border-radius: 5px;
  color: white;
}

.ticketCardPlanview {
  height: 124px;
  // margin: 1rem !important;
  display: grid;
  border-radius: 5px;
  color: white;
}

.ticketCardBlue {
  background-color: #00249B;
}

.ticketCardGreen {
  background-color: #47bc47;
}

.ticketCardRed {
  background-color: #d35352;
}

.ticketCardPink {
  background-color: #d352a5;
}

.ticketCardPurple {
  background-color: #7152d3;
}

.ticketCard{
    height: 130px;
    // width: 206px !important;
    padding: 1rem;
    display: grid;
    border-radius: 5px;
    color: white;
}

.ticketIcon{
    text-align: right;
} 

.ticketCount{
    font-weight: bold;
}

.margintop40px{
  margin-top: 40px !important;
}

.margintop28px{
  margin-top: 28px !important;
}

.ticketDetails{
  font-weight: bold;
  font-size: 18px;
}