.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  .sidebar-list-item {
    display: flex;
    align-items: center;
    padding: 5px 20px 5px 5px;
    border-radius: 7px;
  }
  .MuiListItemIcon-root {
    min-width: 45px !important;
  }
}

#footer {
  // position: fixed;
  position: initial;
  bottom: 30px;
  display: flex;
  margin: auto;
  padding: 35px;
  margin-bottom: 3rem;
}

#footerAdmin {
  position: fixed;
  bottom: 56px;
  display: flex;
  margin: auto;
  padding: 35px;
}
 
.sidebarDrawerOpen {
  .MuiDrawer-paper {
    transform: translateX(0);
    transition: transform 0.2s ease;
  }
  .MuiTypography-root {
    font-size: 13px !important;
  }
}

.sidebarDrawerClose {
  .MuiDrawer-paper {
    transform: translateX(-100%);
    transition: transform 0.2s ease;
  }
  .MuiTypography-root {
    font-size: 13px !important;
  }
}

.sublist-item {
  .listItem {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 0;
  }
  .MuiTypography-root {
    font-size: 12px !important;
  }
  .sidebar-list-item {
    padding: 5px 10px 5px 20px !important;
  }
}

.sidebarDrawer{
  .MuiDrawer-paper {
   overflow-y: hidden;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffffff !important;
}

}

