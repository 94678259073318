.customer-dashboard-cnt {
  padding: 80px 15px 20px 30px;
  .customer-dash-heading {
    padding-bottom: 15px;
    margin-left: 15px;
    font-weight: 600;
  }
  .btn-tile-cnt {
    background-color: #00249b;
    color: white;
    padding: 5px 17px 5px 17px;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    cursor: pointer;
  }
  
}
.ticketHistory{
  h2#modal-modal-title {
    background-color: #00249b;
    color: white;
    padding: 1rem;
  }

.css-15wwp11-MuiTableHead-root {
  background: cornflowerblue !important;
}
.css-1ygcj2i-MuiTableCell-root {
  color: white !important;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight.MuiTableCell-sizeMedium.css-1azl6jz-MuiTableCell-root{
  color: white !important;
}
.img-previewUpload {
  width: 33%;
}
}

