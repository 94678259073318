.sub-addontv-form {
  padding: 40px 40px 40px 40px;
  .sub-addontv-form-title {
    font-weight: 500;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .title-addon-tv {
    font-size: 15px;
    font-weight: bold;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}
