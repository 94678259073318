.formCreatePlan {
  display: grid;
  padding: 5px 30px 25px 30px;
}

.createForm {
  font-size: 15px;
  padding-top: 40px;
}

.btns-stack {
  padding: 5px 30px 25px 30px;
}

.createSearchForm {
  padding-top: 2rem !important;
}

.checkboxDisplay {
  display: grid;
  padding: 2rem;
}

.addPlanForm {
  background: white;
  border-radius: 12px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}

.createForm-btn {
  display: flex !important;
  justify-content: flex-end !important;
}

.bg-blue {
  background-color: #00249b !important;
}

.btn-outlined {
  border: 1px solid #00249b !important;
}

.displayFlex {
  display: flex !important;
}

.rectangle {
  height: 30px;
  width: 50px;
  background-color: #00249b;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.paddingLeft {
  padding-left: 1rem;
}

.marginLeft {
  margin-left: 1rem;
}

.padding {
  padding: 5px;
}

.searchIcon {
  position: relative;
  right: 3rem;
  top: 38px;
}

#free-solo-2-demo {
  height: 1px;
  // width: 21rem;
}

.marginLeftPlan {
  margin-right: 5rem !important;
}

.broadcastPrice {
  padding-left: 25px;
}
@media only screen and (max-width: 600px) {
  .addPlanForm {
    margin-left: 0rem;
    margin-right: 0rem;
    box-shadow: none;
  }
}
