.breadcrumb {
  cursor: pointer;
  p.MuiTypography-root.MuiTypography-body1 {
    font-size: 15px;
  }

  a.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineHover {
    font-size: 15px;
}
.cursorArrow{
  cursor: context-menu !important;
}
}
