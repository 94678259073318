.broadcastBluk-container {
  .createHeader {
    background-color: #00249b;
    margin: 0px !important;
    padding: 13px 13px 13px 5px;
    width: 100%;
    color: white !important;
  }

  .headerpayment {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .settingsbulk-title {
    padding-left: 1rem;
    font-size: 17px;
  }

  .bulkuploadimport {
    font-size: 22px;
  }

  .uploadBtn {
    padding: 10px;
  }

  .bulkheight {
    overflow: scroll;
  }

  .bulk-form-title {
    font-weight: 600;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 13px;
  }

  .fileNameStyle {
    font-size: 15px;
  }

  .bulkUpload-btn {
    margin-left: 10px;
    width: 6.9rem;
  }

  .cancelBtn{
    float: right;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .bulkheight{
    width : 25rem !important;
  }
}