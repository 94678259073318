.add-sub-payment {
  .MuiCheckbox-root {
    padding: 0px !important;
  }
  .type-sub-title {
    padding-top: 40px;
    padding-bottom: 10px;
    font-size: 17px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}
