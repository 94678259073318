.changepsw-container{
  padding-top: 70px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 30px;
  .changepsw-title {
    padding: 20px 0px 30px 0px;
    font-weight: 600;
    font-size: 20px;
  }

}