.addEmployee-container {
  padding-top: 100px;
}
.addTicket-form-cnt {
  .addticket-form-title {
    font-weight: 500;
    font-size: 14px;
  }
}

.marginTopAdd {
  margin-top: 2rem;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.marginTopAdd {
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top: -10px !important;
  }
}

.copyrightsText {
  left: 0;
  bottom: 0;
  width: 100%;
  color: #707070;
  text-align: center;
  font-size: 15px;
}

.addEmployee-icon {
  top: 5px !important;
  position: relative;
}
.sizeless {
  width: 100px !important;
  height: 20px !important;
}

#txtboxsmall {
  height: 10px !important;
}

.addEmployee-container {
  .planHeading {
    padding-left: 6rem;
    color: #131313;
    font-weight: bold;
    font-size: 17px;
    padding-bottom: 1rem;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}

.paddingemp {
  padding: 40px 17px 3px 9px !important;
}


.table-viewable th:first-child {
  width: 5%;
 }