.sub-view-card {
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 17px 0px 17px 0px;
  .sub-card-dark-text {
    font-weight: 600;
    font-size: 13px;
    text-align: center;
  }
  .sub-card-dark-text-inactive{
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: red;
  }
  .secondarytext-sub {
    color: #00249b;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
  }
  .secondarytext-sub-inactive {
    color: red;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
  }
  .spacing-between-tiles {
    padding: 14px 0px 14px 0px;
  }
  .sub-viewCard-btn {
    margin: auto;
  }
  .status-btn-table-active {
    padding: 2px;
    background-color: #70f211;
    color: white;
    border-radius: 6px;
    width: 80px;
    text-align: center;
    margin: auto;
  }
  .status-btn-table-inactive {
    background-color: #c40000;
    padding: 2px;
    color: white;
    border-radius: 6px;
    width: 80px;
    text-align: center;
    margin: auto;
  }
  .name-text-style {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
  }

  .cur-pointer {
    cursor: pointer;
  }
  .centerAlignment{
    margin: auto;
    justify-content: center;
    width: 2rem;
  }
  .expand{
    text-align: right;
    margin-top: 30px;
    padding-right: 70px;
  }
  .spaceBtns{
    margin-left: 1rem;
  }
  .topSpace{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .subeditcardaccordian{
    box-shadow: none !important;
  }

  .subeditcard .MuiAccordionSummary-content{
    justify-content: right;
  }

}
