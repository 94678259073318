.agentList-container {
  padding: 84px 30px 20px 40px;
  .agent-list-table-cntr {
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
    margin-top: 25px;
  }
 
  .search-btns-padding {
    padding: 10px 0px 20px 0px;
  }
  .MuiTableCell-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cur-pointer{
    cursor: pointer;
  }

  .ticketCard{
    height: 95px !important
  }
}

