.ticsearch {
    background: white;
    height: 166px;
    margin-left: 3rem;
    margin-right: 4rem;
    padding: 6px;
    padding: 20px;
    width: 92% !important;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px;
}

.txtright{
    text-align: right;
}

.searchbyfilter .MuiPaper-root{
    width:354px !important;
}

.sortcolor{
    color:blue;
}

.filterHeightDate .MuiOutlinedInput-root{
    height: 35px;
} 

.filterHeight .MuiInputLabel-root{
    margin-top:-9px
}

.filterStyle {
    display: contents !important
}

.filterStylepaddingGrid {
    padding-left: 0px !important;
    padding-right: 15px !important;
}

.paddingTopFilter{
padding-top: 1rem;
}

