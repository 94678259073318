.footer-contents {
  // height: 400px;
  background-color: #005579;
  color: white;
}

.footerPadding{
  padding-right: 1rem;
  padding-left: 1rem;
}

.floatText{
  float: right;
}