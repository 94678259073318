.planList-container {
  padding-top: 70px;
  padding-right: 15px;
  padding-bottom: 20px;
  padding-left: 30px;
  .planlist-title {
    padding: 20px 0px 30px 0px;
    font-weight: 600;
    font-size: 22px;
  }
  .planList-table-padding {
    border-radius: 12px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
  }
  .search-btns-padding {
    padding: 10px 0px 20px 0px;
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: #00249b;
    color: white;
  }
}

@media only screen and (max-width: 425px) {
  .broadcastMultiple {
    width: 95% !important;
  }
}
