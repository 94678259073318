.sub-plan-ctr {
  height: auto;
  border-radius: 23px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  padding-top: 25px;
  padding-right: 20px;
  padding-bottom: 25px;
  padding-left: 20px;

  .downloadPayment {
    cursor: pointer;
  }
}

@media only screen and (max-width: 425px) {
  .downloadTile {
    margin-top: -1rem !important;
  }
}
