.custer-info-card-cnt {
  .title-heading {
    color: #00249b;
    font-size: 14px;
    font-weight: 600;
    padding: 3px 0px 3px 0px;
    text-align: center;
  }
  .sub-value {
    font-size: 14px;
    text-align: center;
  }
}
