.sub-edit-card {
  border: 2px solid;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  padding: 17px 0px 17px 0px;
  .sub-card-dark-text {
    color: #00249b;
    font-size: 17px;
  }
  .secondarytext-sub {
    font-weight: 400;
    font-size: 17px;
  }
  .spacing-between-tiles {
    padding: 14px 0px 14px 0px;
  }
  .sub-viewCard-btn {
    margin: auto;
  }
  .status-btn-table-active {
    padding: 2px;
    background-color: #70f211;
    color: white;
    border-radius: 6px;
    width: 80px;
    text-align: center;
    margin: auto;
  }
  .status-btn-table-inactive {
    background-color: #c40000;
    padding: 2px;
    color: white;
    border-radius: 6px;
    width: 80px;
    text-align: center;
    margin: auto;
  }
  .name-text-style {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
  }

  .cur-pointer {
    cursor: pointer;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
}


.avatarsubedit .css-1d3bft6-MuiAvatar-root{
  width: 85px !important;
    height: 80px !important
}

.padding-sub-edit{
  padding: 15px;
}

.subEditmargin{
  margin-top: 20px;
}



@media only screen and (max-width: 320px) {
  .addIcon{
    margin-top: 11px !important;
  }
  // svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
  //   margin-top: 37px !important;
  // }
}

@media only screen and (max-width: 425px) {
  .editUpdate{
    margin-top: 11px !important;
  }
  // svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
  //   margin-top: 37px !important;
  // }
}

