.colorBlue {
  color: #00249b;
  font-weight: bold;
}

.editbtn {
  display: grid;
  width: 48% !important;
}
.profileBtn {
  margin-bottom: 123px !important;
}
.user-dts-title {
  font-size: 14px;
}

.imgPreview {
  width: 8.7rem;
  margin-bottom: 15px;
  border: 5px solid lightgray;
  height: 10rem;
}

@media only screen and (max-width: 1024px) {
  .editbtn {
    display: grid;
    width: 78% !important;
  }
}

.img-previewUpload {
  width: 100%;
  height: 100%;
}

.topBarUpload {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.adjust-bal {
  margin-left: 1rem !important;
}

.deleteProf{
  margin-top: 1rem !important;
}
