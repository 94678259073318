.addTicket-container {
  padding: 25px 40px 20px 40px;  
}



@media only screen and (max-width: 425px) {
  .bulksms-container{
    padding: 20px 0px 0px 0px !important;
  }
}
